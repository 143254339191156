import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Nosov.Digital ВКС"
        titleTemplate="%s"
        defaultTitle="Nosov.Digital ВКС"
        description="Присоединитесь к звонку!"
        openGraph={{
          url: 'https://meet.nosov.io',
          images: [
            {
              url: 'https://meet.nosov.io/images/logos.jpg',
              width: 500,
              height: 500,
              type: 'image/jpg',
            },
          ],
          site_name: 'Nosov.Digital ВКС',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
          {
            rel: 'apple-touch-icon',
            href: '/images/livekit-apple-touch.png',
            sizes: '180x180',
          },
          {
            rel: 'mask-icon',
            href: '/images/livekit-safari-pinned-tab.svg',
            color: '#070707',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
